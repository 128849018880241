import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./Content3.css";
import digital from "../assets/fully-digital-blue.svg";
import data from "../assets/data-driven-blue.svg";
import transparency from "../assets/complete-transparency-blue.svg";
import endtoend from "../assets/end-to-end-blue.svg";

const Content3 = () => {
  const advantages = [
    {
      img: data,
      title: "Data-Driven",
      text: "We leverage data and on-the-ground experience to identify and list only the best opportunities.",
    },
    {
      img: transparency,
      title: "Complete Transparency",
      text: "Our investment process is completely transparent and comes with detailed reporting at every step of the way.",
    },
    {
      img: endtoend,
      title: "End-to-End Management",
      text: "We manage the asset from acquisition to exit. Investors enjoy a hands-off investment experience.",
    },
    {
      img: digital,
      title: "Fully-Digital Experience",
      text: "Investing with Investist is completely online, no physical paperwork. Invest and track your portfolio anytime.",
    },
  ];

  return (
    <Container fluid className=" bg-dark-blue3">
      <Container className="px-1">
        {/* <h2 className="text-center mb-4 section-title3">Investist Advantage</h2>
        <p className="text-center mb-5 section-subtitle3">
          Investist investors get exclusive access to our curated opportunities
          and enjoy a range of advantages.
        </p> */}
        <Row className="justify-content-center gx-4 gy-4">
          {advantages.map((item, index) => (
            <Col
              xs={6}
              sm={6}
              md={5}
              lg={5}
              xl={3}
              className="d-flex justify-content-center"
              key={index}
            >
              <div className="card-hover3">
                <div className="icon-container3 mb-3">
                  <img src={item.img} alt={item.title} className="icon3 h-100 w-100"/>
                </div>
                <h3 className="card-title3">{item.title}</h3>
                <div className="seperator"></div>
                <p className="card-text3">{item.text}</p>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </Container>
  );
};

export default Content3;
