import axios from "axios";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;


const USER_API = {
    signUp: async (payload) => {
        return axios.request({
            url: `${BACKEND_URL}add-user`,
            method: 'post',
            data: payload,
            withCredentials: true,
        })
    },

    login: async (payload) => {
        return axios.request({
            url: `${BACKEND_URL}login`,
            method: 'post',
            data: payload,
            withCredentials: true,
        })
    },
    RetrieveLoginInfo: () => {
        return axios.request({
            url: `${BACKEND_URL}is-logged-in`,
            method: 'post',
            withCredentials: true,
        })
    },
    Logout: () => {
        return axios.request({
            url: `${BACKEND_URL}logout`,
            method: 'post',
            withCredentials: true,
        })
    },
    requestPasswordReset: async (payload) => {
      return axios.request({
        url: `${BACKEND_URL}request-password-reset`,
        method: 'post',
        data: payload,
      });
    },
  
    verifyOtp: async (payload) => {
      return axios.request({
        url: `${BACKEND_URL}verify-otp`,
        method: 'post',
        data: payload,
      });
    },

    resetPassword: async (payload) => {
      return axios.request({
        url: `${BACKEND_URL}reset-password`,
        method: 'post',
        data: payload,
      });
    }
}

export default USER_API;