import React, { createContext, useState, useEffect } from "react";
import USER_API from "../services/UserService";
import { useLocation, useNavigate } from "react-router-dom";
import { getAuth, onAuthStateChanged } from "firebase/auth";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const navigate = useNavigate();
  const auth = getAuth();
  const location = useLocation();

  useEffect(() => {
    const retrieveLoginInfo = async () => {
      try {
        const res = await USER_API.RetrieveLoginInfo();
        if (res.status === 200) {
          setCurrentUser(res.data);
          navigate(location.pathname);
        } else {
          setCurrentUser(null);
        }
      } catch (error) {
        setCurrentUser(null);
      }
    };

    retrieveLoginInfo();
  }, [currentUser?.name]);

  const LoginUser = (data) => {
    if (data) {
      setCurrentUser(data);
    }
  };

  const LogOutUser = () => {
    setCurrentUser(null);
  };

  return (
    <AuthContext.Provider value={{ currentUser, LoginUser, LogOutUser }}>
      {children}
    </AuthContext.Provider>
  );
};
