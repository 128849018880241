// import React, { useState, useEffect } from "react";
// import { useForm } from "react-hook-form";
// import { uploadImage } from "../helper/S3_bucket"; // S3 bucket upload helper
// import KYC_API from "../services/KycService";
// import { v4 as uuidv4 } from "uuid";
// import { toast } from "react-toastify";
// import "./KycForm.css";
// import { ToastContainer } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';

// const KycForm = ({ setOnePager }) => {
//   const {
//     register,
//     handleSubmit,
//     formState: { errors },
//     reset
//   } = useForm();
//   const [panCardImage, setPanCardImage] = useState(null);
//   const [addressProofImage, setAddressProofImage] = useState(null);

//   const validImageTypes = ["image/jpeg", "image/png", "image/jpg", "image/webp"];
//   const maxSize = 5 * 1024 * 1024; // 5MB

//   const onSubmit = async (data) => {
//     try {

//       // Validate PAN Card Image
//       if (
//         !data.panCardImage[0] ||
//         !validImageTypes.includes(data.panCardImage[0].type) ||
//         data.panCardImage[0].size > maxSize
//       ) {
//         console.log(data.panCardImage.size);
//         toast.error(
//           "Please upload a valid PAN card image (jpg, jpeg, png) under 5MB."
//         );
//         return;
//       }

//       // Validate Address Proof Image
//       if (
//         !data.addressProofImage[0] ||
//         !validImageTypes.includes(data.addressProofImage[0].type) ||
//         data.addressProofImage[0].size > maxSize
//       ) {
//         toast.error(
//           "Please upload a valid address proof image (jpg, jpeg, png) under 5MB."
//         );
//         return;
//       }

//       // Upload PAN Card Image to S3
//       const panCardKey = `kyc/${uuidv4()}.${
//         data.panCardImage[0].name.split(".").reverse()[0]
//       }`;
//       const panCardUpload = await uploadImage(
//         data.panCardImage[0],
//         panCardKey,
//         data.panCardImage[0].type
//       );

//       // Upload Address Proof Image to S3
//       const addressProofKey = `kyc/${uuidv4()}.${
//         data.addressProofImage[0].name.split(".").reverse()[0]
//       }`;
//       const addressProofUpload = await uploadImage(
//         data.addressProofImage[0],
//         addressProofKey,
//         data.addressProofImage[0].type
//       );

//       if (panCardUpload && addressProofUpload) {
//         // Send form data along with image URLs to the backend
//         const kycData = {
//           ...data,
//           panCardUrl: panCardUpload, // S3 URL for PAN Card
//           addressProofUrl: addressProofUpload, // S3 URL for Address Proof
//         };

//         const response = await KYC_API.AddKycDetails(kycData);
//         console.log("API Response:", response);

//         if (response.status === 200) {
//           toast.success("KYC form submitted successfully!");
//           reset()
//         } else {
//           toast.error("Failed to submit KYC form.");
//         }
//       } else {
//         toast.error("Failed to upload images.");
//       }
//     } catch (error) {
//       console.error("Error submitting KYC form:", error);
//       toast.error("An error occurred while submitting the form.");
//     }
//   };

//   // const handlePanCardChange = (e) => {
//   //   console.log();

//   //   setPanCardImage(e.target.files[0]);
//   // };

//   const handleAddressProofChange = (e) => {
//     setAddressProofImage(e.target.files[0]);
//   };

//   useEffect(() => {
//     setOnePager(true);
//   }, [setOnePager]);

//   console.log(panCardImage);

//   return (
//     <div className="kyc-form-container">
//       <h1>Investist KYC Form</h1>
//       <form onSubmit={handleSubmit(onSubmit)}>
//         {/* Document Section */}
//         <div className="form-group document-section">
//           <h2>Document Submission</h2>
//           <p>Please upload the following documents:</p>

//           <label>
//             PAN Card <span className="required">(Mandatory)</span>:
//           </label>
//           <input
//             type="file"
//             accept="image/*"
//             onChange={(e) => {
//               console.log(e.target);
//               setPanCardImage(e.target.files[0]);
//             }}
//             {...register("panCardImage", { required: true })}
//           />
//           {errors.panCardImage && (
//             <span className="error">PAN Card image is required</span>
//           )}

//           <label>
//             Address Proof (Aadhar Card or Driving License){" "}
//             <span className="required">(Mandatory)</span>:
//           </label>
//           <input
//             type="file"
//             accept="image/*"
//             onChange={handleAddressProofChange}
//             {...register("addressProofImage", { required: true })}
//           />
//           {errors.addressProofImage && (
//             <span className="error">Address proof image is required</span>
//           )}
//         </div>

//         {/* Client Details Section */}
//         <div className="form-group">
//           <h2>Client Details</h2>
//           <label>
//             Applicant's Name <span className="required">*</span>:
//           </label>
//           <input
//             type="text"
//             {...register("applicantName", { required: true })}
//           />
//           {errors.applicantName && (
//             <span className="error">Applicant's name is required</span>
//           )}

//           <label>
//             Mobile No. <span className="required">*</span>:
//           </label>
//           <input
//             type="tel"
//             {...register("mobileNo", {
//               required: true,
//               pattern: /^[0-9]{10}$/,
//             })}
//           />
//           {errors.mobileNo && (
//             <span className="error">Valid mobile number is required</span>
//           )}

//           <label>
//             Email <span className="required">*</span>:
//           </label>
//           <input type="email" {...register("email", { required: true })} />
//           {errors.email && (
//             <span className="error">Valid email is required</span>
//           )}

//           <label>
//             Occupation <span className="required">*</span>:
//           </label>
//           <input type="text" {...register("occupation", { required: true })} />
//           {errors.occupation && (
//             <span className="error">Occupation is required</span>
//           )}

//           <label>
//             Annual Income (INR) <span className="required">*</span>:
//           </label>
//           <input
//             type="number"
//             {...register("annualIncome", { required: true })}
//             placeholder="Enter amount in INR"
//           />
//           {errors.annualIncome && (
//             <span className="error">Annual income is required</span>
//           )}

//           <label>
//             Investment Amount (INR) <span className="required">*</span>:
//           </label>
//           <input
//             type="number"
//             {...register("investmentAmount", { required: true })}
//             placeholder="Enter amount in INR"
//           />
//           {errors.investmentAmount && (
//             <span className="error">Investment amount is required</span>
//           )}
//         </div>

//         {/* Nominee Details Section */}
//         <div className="form-group">
//           <h2>Nominee Details</h2>
//           <label>
//             Nominee's Name <span className="required">*</span>:
//           </label>
//           <input type="text" {...register("nomineeName", { required: true })} />
//           {errors.nomineeName && (
//             <span className="error">Nominee's name is required</span>
//           )}

//           {/* Date Field */}
//           <label>Nominee's Dob:</label>
//           <input type="date" {...register("date", { required: true })} />
//           {errors.date && (
//             <span className="error">Nominee's Dob is required</span>
//           )}

//           <label>
//             Nominee's Pan Number <span className="required">*</span>:
//           </label>
//           <input type="text" {...register("nomineePAN", { required: true })} />
//           {errors.nomineePAN && (
//             <span className="error">Nominee's Pan Number is required</span>
//           )}

//           <label>
//             Relationship with applicant <span className="required">*</span>:
//           </label>
//           <input
//             type="text"
//             {...register("relationshipWithApplicant", { required: true })}
//           />
//           {errors.relationshipWithApplicant && (
//             <span className="error">
//               Relationship with applicant is required
//             </span>
//           )}
//         </div>

//         <button type="submit" className="submit_btn">
//           Submit
//         </button>
//       </form>
//       <ToastContainer/>
//     </div>
//   );
// };

// export default KycForm;

import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { uploadImage } from "../helper/S3_bucket"; // S3 bucket upload helper
import KYC_API from "../services/KycService";
import { v4 as uuidv4 } from "uuid";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom"; // For redirection
import Modal from "react-bootstrap/Modal"; // For Modal
import "react-toastify/dist/ReactToastify.css";
import "./KycForm.css";

const KycForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const [panCardImage, setPanCardImage] = useState(null);
  const [addressProofImage, setAddressProofImage] = useState(null);
  const [showModal, setShowModal] = useState(false); // To control modal visibility
  const navigate = useNavigate(); // For redirection

  const validImageTypes = [
    "image/jpeg",
    "image/png",
    "image/jpg",
    "image/webp",
  ];
  const maxSize = 5 * 1024 * 1024; // 5MB

  const onSubmit = async (data) => {
    try {
      // Validate PAN Card Image
      if (
        !data.panCardImage[0] ||
        !validImageTypes.includes(data.panCardImage[0].type) ||
        data.panCardImage[0].size > maxSize
      ) {
        toast.error(
          "Please upload a valid PAN card image (jpg, jpeg, png) under 5MB."
        );
        return;
      }

      // Validate Address Proof Image
      if (
        !data.addressProofImage[0] ||
        !validImageTypes.includes(data.addressProofImage[0].type) ||
        data.addressProofImage[0].size > maxSize
      ) {
        toast.error(
          "Please upload a valid address proof image (jpg, jpeg, png) under 5MB."
        );
        return;
      }

      // Upload PAN Card Image to S3
      const panCardKey = `kyc/${uuidv4()}.${
        data.panCardImage[0].name.split(".").reverse()[0]
      }`;
      const panCardUpload = await uploadImage(
        data.panCardImage[0],
        panCardKey,
        data.panCardImage[0].type
      );

      // Upload Address Proof Image to S3
      const addressProofKey = `kyc/${uuidv4()}.${
        data.addressProofImage[0].name.split(".").reverse()[0]
      }`;
      const addressProofUpload = await uploadImage(
        data.addressProofImage[0],
        addressProofKey,
        data.addressProofImage[0].type
      );

      if (panCardUpload && addressProofUpload) {
        // Send form data along with image URLs to the backend
        const kycData = {
          ...data,
          panCardUrl: panCardUpload,
          addressProofUrl: addressProofUpload,
        };

        const response = await KYC_API.AddKycDetails(kycData);

        if (response.status === 200) {
          toast.success("KYC form submitted successfully!");
          reset();
          // Show success modal
          setShowModal(true);

          // Hide modal and redirect after 10 seconds
          setTimeout(() => {
            setShowModal(false);
            navigate("/mhowProperty"); // Redirect to the desired page
          }, 10000); // 10 seconds timeout
        } else {
          toast.error("Failed to submit KYC form.");
        }
      } else {
        toast.error("Failed to upload images.");
      }
    } catch (error) {
      console.error("Error submitting KYC form:", error);
      toast.error("An error occurred while submitting the form.");
    }
  };


  return (
    <div className="kyc-form-container">
      <h1>Investist KYC Form</h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        {/* Document Section */}
        <div className="form-group document-section">
          <h2>Document Submission</h2>
          <p>Please upload the following documents:</p>

          <label>
            PAN Card <span className="required">(Mandatory)</span>:
          </label>
          <input
            type="file"
            accept="image/*"
            onChange={(e) => setPanCardImage(e.target.files[0])}
            {...register("panCardImage", { required: true })}
          />
          {errors.panCardImage && (
            <span className="error">PAN Card image is required</span>
          )}

          <label>
            Address Proof (Aadhar Card or Driving License){" "}
            <span className="required">(Mandatory)</span>:
          </label>
          <input
            type="file"
            accept="image/*"
            onChange={(e) => setAddressProofImage(e.target.files[0])}
            {...register("addressProofImage", { required: true })}
          />
          {errors.addressProofImage && (
            <span className="error">Address proof image is required</span>
          )}
        </div>

        {/* Client Details Section */}
        <div className="form-group">
          <h2>Client Details</h2>
          <label>
            Applicant's Name <span className="required">*</span>:
          </label>
          <input
            type="text"
            {...register("applicantName", { required: true })}
          />
          {errors.applicantName && (
            <span className="error">Applicant's name is required</span>
          )}

          <label>
            Mobile No. <span className="required">*</span>:
          </label>
          <input
            type="tel"
            {...register("mobileNo", {
              required: true,
              pattern: /^[0-9]{10}$/,
            })}
          />
          {errors.mobileNo && (
            <span className="error">Valid mobile number is required</span>
          )}

          <label>
            Email <span className="required">*</span>:
          </label>
          <input type="email" {...register("email", { required: true })} />
          {errors.email && (
            <span className="error">Valid email is required</span>
          )}

          <label>
            Occupation <span className="required">*</span>:
          </label>
          <input type="text" {...register("occupation", { required: true })} />
          {errors.occupation && (
            <span className="error">Occupation is required</span>
          )}

          <label>
            Annual Income (INR) <span className="required">*</span>:
          </label>
          <input
            type="number"
            {...register("annualIncome", { required: true })}
            placeholder="Enter amount in INR"
          />
          {errors.annualIncome && (
            <span className="error">Annual income is required</span>
          )}

          <label>
            Investment Amount (INR) <span className="required">*</span>:
          </label>
          <input
            type="number"
            {...register("investmentAmount", { required: true })}
            placeholder="Enter amount in INR"
          />
          {errors.investmentAmount && (
            <span className="error">Investment amount is required</span>
          )}
        </div>

        {/* Nominee Details Section */}
        <div className="form-group">
          <h2>Nominee Details</h2>
          <label>
            Nominee's Name <span className="required">*</span>:
          </label>
          <input type="text" {...register("nomineeName", { required: true })} />
          {errors.nomineeName && (
            <span className="error">Nominee's name is required</span>
          )}

          <label>Nominee's Dob:</label>
          <input type="date" {...register("date", { required: true })} />
          {errors.date && (
            <span className="error">Nominee's Dob is required</span>
          )}

          <label>
            Nominee's Pan Number <span className="required">*</span>:
          </label>
          <input type="text" {...register("nomineePAN", { required: true })} />
          {errors.nomineePAN && (
            <span className="error">Nominee's Pan Number is required</span>
          )}

          <label>
            Relationship with applicant <span className="required">*</span>:
          </label>
          <input
            type="text"
            {...register("relationshipWithApplicant", { required: true })}
          />
          {errors.relationshipWithApplicant && (
            <span className="error">
              Relationship with applicant is required
            </span>
          )}
        </div>

        <button type="submit" className="submit_btn">
          Submit
        </button>
      </form>

      <ToastContainer />

      {/* Modal for success message */}
      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header closeButton className="bg-success text-white">
          <Modal.Title>
            <span className="me-2">✅</span> KYC Submission Successful
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <h5>🎉 Congratulations!</h5>
          <p className="mb-4">
            Your KYC form has been successfully submitted! <br />
            Our team will reach out to you shortly.
          </p>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default KycForm;
