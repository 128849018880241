import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import "./Properties.css";
import { AuthContext } from "../helper/AuthenticationState";
import InvestForm from "../components/InvestForm";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import PROPERTIES_API from "../services/PropertyService";
import { useDispatch } from "react-redux";
import { setDetailProperty } from "../redux/feature/PropertySlice";
import { ImLocation } from "react-icons/im";

const Properties = () => {
  const navigate = useNavigate();
  const { currentUser } = useContext(AuthContext);
  const [showForm, setShowForm] = useState(false);
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [allProperty, setAllProperty] = useState([]);
  const [featuedProperty, setFeatuedProperty] = useState([]);
  const dispatch = useDispatch();

  const handleButtonClick = (url, property = null) => {
    if (!currentUser) {
      navigate("/login");
    } else {
      if (url === "invest") {
        setSelectedProperty(property);
        setShowForm(true);
      } else {
        navigate(url);
      }
    }
  };

  const handleClose = () => {
    setShowForm(false);
    setSelectedProperty(null);
  };

  const getAllProperty = async () => {
    const res = await PROPERTIES_API.getProperty();
    if (res.status === 200) {
      setAllProperty(res.data);
      const featuredProperties = res.data.filter(
        (property) => property.featuredProperty
      );
      setFeatuedProperty(featuredProperties);
    }
  };

  useEffect(() => {
    getAllProperty();
  }, []);

  const handleViewDetail = (property) => {
    if (currentUser !== null) {
      dispatch(setDetailProperty(property));
      navigate("/property");
    } else {
      navigate("/login");
    }
  };

  return (
    <div className="properties-page">
      <div className="container py-5 properties-container">
        <div className="row">
          <div className="col">
            <div className="text-center mb-4 heading_text pt-xl-4 pt-lg-4 pt-md-4">
              <h2 className="text-white fw-semibold">Investist</h2>
              <h1 className="fw-bold" style={{ color: "#6fd441" }}>
                PROPERTIES
              </h1>
              <h3 className="text-white fw-semibold">
                Our Handpicked Portfolio
              </h3>
            </div>
          </div>

          <div className="col-md-6 col-sm-12">
            <div
              id="propertyCarousel"
              className="carousel slide property_card_floating m-0 p-0"
              data-bs-ride="carousel"
              data-bs-interval="4000" 
            >
              <div className="carousel-inner m-0 p-0">
                {featuedProperty.map((property, index) => (
                  <div
                    className={`carousel-item ${index === 0 ? "active" : ""}`} // Active for the first item
                    key={index}
                  >
                    <div
                      className="featured_property container"
                      style={{ overflow: "hidden", padding: "1.5rem 0rem", paddingTop:"0" }}
                    >
                      <h2 className="text-white">{property.propertyName}</h2>
                      <div className="row ">
                        <div className="col featured_property_image">
                          <img
                            src={`https://investistt.s3.ap-south-1.amazonaws.com/${property.images[0]}`}
                            alt="Property_Image"
                            className="w-100 h-100"
                          />
                        </div>
                        <div className="col">
                          <div className="container">
                            <div className="row">
                              <div className="col featured_property_text">
                                <h5>Location</h5>
                                <p>{property.location}</p>
                              </div>
                              <div className="col featured_property_text">
                                <h5>Asset Value</h5>
                                <p>₹{property.price}</p>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col featured_property_text">
                                <h5>Entry Price</h5>
                                <p>₹{property.minimumInvestment}</p>
                              </div>
                              <div className="col featured_property_text">
                                <h5>Target IRR</h5>
                                <p>{property.targetIRR}%</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="button-group-floating">
                          <button
                            className="btn btn_invest_float action_btn_float"
                            onClick={() =>
                              handleButtonClick("invest", property)
                            }
                          >
                            Invest Now
                          </button>
                          <button
                            className="btn action_btn_float"
                            onClick={() => handleViewDetail(property)}
                          >
                            View Details
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>

              {/* Custom Dashes as Carousel Indicators */}
              <div className="carousel-indicators d-flex justify-content-center carousel_dash">
                {featuedProperty.map((_, index) => (
                  <button
                    type="button"
                    data-bs-target="#propertyCarousel"
                    data-bs-slide-to={index}
                    className={`fs-2 text-white ${index === 0 ? "active" : ""}`}
                    aria-current={index === 0 ? "true" : "false"}
                    aria-label={`Slide ${index + 1}`}
                    key={index}
                  >
                   
                  </button>
                ))}
              </div>
            </div>
          </div>
        </div>

        <div className="row property_card_section">
          {/* <h1 className="text-danger">All</h1> */}
          {allProperty.map((property, index) => (
            <div key={index} className="col-lg-6 col-md-12 mb-4">
              <div className="property-card">
                <img
                  className="d-block w-100 property-main-image"
                  src={`https://investistt.s3.ap-south-1.amazonaws.com/${property.images[0]}`}
                  alt={property.propertyName}
                />
                <div className="property_details">
                  <h2 className="property_title2">
                    {property.propertyName}
                    <br />
                    <span className="location_text"><ImLocation/> {property.location}</span>
                  </h2>

                  <div className="row mb-3 ">
                    <div className="col-4">
                      <div className="property_details_comp">
                        <h5>Asset Price</h5>
                        <p className="text-muted2">₹{property.price}</p>
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="property_details_comp">
                        <h5>Entry Price</h5>
                        <p className="text-muted2">
                          ₹{property.minimumInvestment}
                        </p>
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="property_details_comp">
                        <h5>Expected IRR</h5>
                        <p className="text-muted2">{property.targetIRR}%</p>
                      </div>
                    </div>
                  </div>
                  <div className="button-group">
                    <button
                      className="btn btn_invest action_btn"
                      onClick={() => handleButtonClick("invest", property)}
                    >
                      Invest Now
                    </button>
                    <button
                      className="btn action_btn"
                      onClick={() => handleViewDetail(property)}
                    >
                      View Details
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <Modal show={showForm} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Invest in {selectedProperty?.propertyName}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedProperty && (
            <InvestForm
              propertyName={selectedProperty.propertyName}
              size={selectedProperty.size}
              onClose={handleClose}
            />
          )}
        </Modal.Body>
      </Modal>
      <ToastContainer />
    </div>
  );
};

export default Properties;
