import axios from 'axios'

const BACKEND_API = process.env.REACT_APP_BACKEND_URL

const PROPERTIES_API = {
    getProperties: () => {
        return axios.request({
            url: `${BACKEND_API}properties`,
            method: 'get',
            withCredentials: true
        })
    },
    getProperty: () => {
        return axios.request({
            url: `${BACKEND_API}get-property`,
            method: 'get',
            withCredentials: true
        })
    },
}

export default PROPERTIES_API