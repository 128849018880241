// import React, { useState, useEffect } from "react";
// import "./News.css";
// import NEWS_API from "../services/NewsService";

// const News = () => {
//   const [newsItems, setNewsItems] = useState([]);
//   const [modalData, setModalData] = useState(null);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);

//   useEffect(() => {
//     const fetchNews = async () => {
//       try {
//         const response = await NEWS_API.getNews();
//         setNewsItems(response.data);

//         setLoading(false);
//       } catch (err) {
//         setError(err.message || "Failed to fetch news.");
//         setLoading(false);
//       }
//     };

//     fetchNews();
//   }, []);

//   const handleReadMore = (newsItem) => {
//     setModalData(newsItem);
//   };

//   const handleCloseModal = () => {
//     setModalData(null);
//   };

//   if (loading) {
//     return <div>Loading...</div>;
//   }

//   if (error) {
//     return <div>Error: {error}</div>;
//   }

//   return (
//     <div className="news-container">
//       <h1
//         className="text-center mb-4 fw-bolder"
//         style={{ height: "15vh", alignItems: "center", alignContent: "center" }}
//       >
//         Market Report
//       </h1>
//       <div className="news-grid">
//         {newsItems.map((newsItem) => (
//           <div key={newsItem._id} className="news-item">
//             {newsItem.headline && (
//               <h5 className="headline">{newsItem.headline}</h5>
//             )}
//             {newsItem.image && (
//               <img
//                 src={`https://investistt.s3.ap-south-1.amazonaws.com/${newsItem.image}`} // Mapping image name to imported image path
//                 alt={newsItem.headline || "News Image"}
//                 className="news-image"
//               />
//             )}
//             <button
//               onClick={() => handleReadMore(newsItem)}
//               className="btn btn-primary"
//             >
//               Read More
//             </button>
//           </div>
//         ))}
//       </div>

//       {modalData && (
//         <div
//           className="modal fade show"
//           style={{ display: "block" }}
//           tabIndex="-1"
//           role="dialog"
//         >
//           <div
//             className="modal-dialog modal-dialog-centered"
//             role="document"
//             id="staticBackdrop"
//             data-bs-backdrop="static"
//             data-bs-keyboard="false"
//             aria-labelledby="staticBackdropLabel"
//             aria-hidden="true"
//           >
//             <div className="modal-content">
//               <div className="modal-header">
//                 <h4 className="modal-title" id="staticBackdropLabel">
//                   {modalData.headline}
//                 </h4>
//               </div>
//               <div className="modal-body">
//                 {modalData.image && (
//                   <img
//                     src={`https://investistt.s3.ap-south-1.amazonaws.com/${modalData.image}`} // Mapping image name to imported image path
//                     alt={modalData.headline || "News Image"}
//                     className="img-fluid mb-3"
//                   />
//                 )}
//                 <p
//                   style={{ fontSize: "14px" }}
//                   dangerouslySetInnerHTML={{ __html: modalData.body }}
//                 ></p>
//               </div>
//               <div className="modal-footer">
//                 <button
//                   type="button"
//                   className="btn btn-secondary"
//                   onClick={handleCloseModal}
//                 >
//                   Close
//                 </button>
//               </div>
//             </div>
//           </div>
//         </div>
//       )}
//       <div className="d-flex justify-content-center text-center mt-4">
//         <button className="btn btn-primary m-2">Load More</button>
//       </div>
//     </div>
//   );
// };

// export default News;

import React, { useState, useEffect } from "react";
import "./News.css";
import NEWS_API from "../services/NewsService";

const News = () => {
  const [newsItems, setNewsItems] = useState([]);
  const [modalData, setModalData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchNews = async () => {
      try {
        const response = await NEWS_API.getNews();
        setNewsItems(response.data);

        setLoading(false);
      } catch (err) {
        setError(err.message || "Failed to fetch news.");
        setLoading(false);
      }
    };

    fetchNews();
  }, []);

  const handleReadMore = (newsItem) => {
    setModalData(newsItem);
  };

  const handleCloseModal = () => {
    setModalData(null);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="news-container">
      <h1
        className="text-start mb-4 fw-bolder news-head"
        style={{ height: "15vh", alignItems: "center", alignContent: "center" }}
      >
        Market Report
      </h1>
      <div className="news-grid">
        {newsItems.map((newsItem) => (
          <div key={newsItem._id} className="news-item" onClick={() => handleReadMore(newsItem)}>
            {newsItem.image && (
              <div className="newsitem_d"><img
              src={`https://investistt.s3.ap-south-1.amazonaws.com/${newsItem.image}`} // Mapping image name to imported image path
              alt={newsItem.headline || "News Image"}
              className="news-image"
            /></div>
            )}
            {newsItem.headline && (
              <h5 className="headline">{newsItem.headline}... <span className="learn_more"><br/>Learn More</span></h5>
            )}
            {/* <div className="btnN"><button
              onClick={() => handleReadMore(newsItem)}
              className="btn-primaryN"
            >
              Read More
            </button></div> */}
          </div>
        ))}
      </div>

      {modalData && (
        <div
          className="modal fade show"
          style={{ display: "block" }}
          tabIndex="-1"
          role="dialog"
        >
          <div
            className="modal-dialog modal-dialog-centered"
            role="document"
            id="staticBackdrop"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title" id="staticBackdropLabel">
                  {modalData.headline}
                </h4>
              </div>
              <div className="modal-body">
                {modalData.image && (
                  <img
                    src={`https://investistt.s3.ap-south-1.amazonaws.com/${modalData.image}`} // Mapping image name to imported image path
                    alt={modalData.headline || "News Image"}
                    className="img-fluid mb-3"
                  />
                )}
                <p
                  style={{ fontSize: "14px" }}
                  dangerouslySetInnerHTML={{ __html: modalData.body }}
                ></p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className=""
                  onClick={handleCloseModal}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* <div className="d-flex justify-content-center text-center mt-4">
        <button className="btn btn-primary m-2">Load More</button>
      </div> */}
    </div>
  );
};

export default News;
