import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    detailProperty: null,
}

export const PropertySlice = createSlice({
    name: 'property',
    initialState,
    reducers: {
        setDetailProperty: (state, action) => {
            state.detailProperty = action.payload;
        }
    },
})

// Action creators are generated for each case reducer function
export const { setDetailProperty } = PropertySlice.actions

export default PropertySlice.reducer