import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

export const firebaseConfig = {
  apiKey: "AIzaSyDrkmDyR76TKp09H18PZZo1F8zXJw_lh6U",
  authDomain: "investistt-5260a.firebaseapp.com",
  projectId: "investistt-5260a",
  storageBucket: "investistt-5260a.appspot.com",
  messagingSenderId: "170699625713",
  appId: "1:170699625713:web:0eed9c07be83bde7a74550",
  measurementId: "G-E985GC8NK0"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);