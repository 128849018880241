import React, { useState } from 'react';
import './Privacy.css';
import privacyData from '../Privacy.json';

const Privacy = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleSection = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="prop-bg">
      <h1 className="fw-bold text-center py-4" style={{ color: "#FFA500" }}>
        Privacy Policy
      </h1>
      <div className="privacy-container">
        {privacyData.sections.map((section, index) => (
          <div
            key={index}
            className={`privacy-item ${activeIndex === index ? 'active' : ''}`}
            onClick={() => toggleSection(index)}
          >
            <div className="privacy-title-container">
              <h3 className="privacy-title">{section.heading}</h3>
            </div>
            {activeIndex === index && <p className="privacy-content">{section.content}</p>}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Privacy;
