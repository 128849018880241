import React, { useContext, useState, useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import InvestForm from "./InvestForm";
import "./Property.css"; 
import { ToastContainer } from "react-toastify";
import { useSelector } from "react-redux";
import { AuthContext } from "../helper/AuthenticationState";
import { ImLocation } from "react-icons/im";

const Property = () => {
  const { currentUser } = useContext(AuthContext);
  const [showForm, setShowForm] = useState(false);
  const property = useSelector((state) => state.property.detailProperty);
  const navigate = useNavigate();
  const [active,setActive]= useState(0)

  useEffect(() => {
    if (!currentUser) {
      navigate("/"); 
    }
  }, [currentUser, navigate]);

  if (!property) return <Navigate to="/properties" />;

  const renderPropertyDetails = () => (
    <div className="property_box3">
      <div className="property_item3">
        <div className="property_label3">₹{property.minimumInvestment}</div>
        <div className="property_text3">Entry Price</div>
      </div>
      <div className="property_item3">
        <div className="property_label3">{property.targetIRR}%</div>
        <div className="property_text3">Expected IRR</div>
      </div>
      <div className="property_item3">
        <div className="property_label3">₹{property.price}</div>
        <div className="property_text3">Asset Value</div>
      </div>
      <div className="property_item3">
        <div className="property_label3">{property.size} sqft</div>
        <div className="property_text3">Size</div>
      </div>
    </div>
  );

  return (
    <div className="properties-container3">
        <div id="carouselExampleIndicators" class="carousel slide" data-bs-ride="carousel" >
          <div class="carousel-indicators">
            {
              property.images.map((ele,idx)=>{
                return (<button
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to={idx}
                class={idx === active && 'active'}
                aria-current="true"
                aria-label={`Slide ${idx}`}
                onClick={()=>setActive(idx)}
              ></button>)
              })
            }
          </div>
          <div class="carousel-inner">
          {
              property.images.map((ele,idx)=>{
                return (
                <div class={`carousel-item ${+idx === active && 'active'} p-0`}>
                  <img
                    src={`https://investistt.s3.ap-south-1.amazonaws.com/${ele}`}
                    class="d-block crousal-image"
                    alt="..."
                  />
              </div>)
              })
          }
          </div>
          <button
            class="carousel-control-prev"
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide="prev"
          >
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button
            class="carousel-control-next"
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide="next"
          >
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>

      <div className="property_details3">
        <div className="property-title3">{property.propertyName}</div>
        <div className="property_location3">
          <ImLocation /> {property.location}
        </div>

        {renderPropertyDetails()}

        <div className="rfi_section3">
          <h2>Reason for Investing</h2>
          <p>{property.reasonForInvesting}</p>
        </div>

        <div className="atp_section3">
          <h2>About the Property</h2>
          <p>{property.aboutTheProperty}</p>
        </div>
      </div>

      <button className="invest_button3" onClick={() => setShowForm(true)}>
        Invest Now
      </button>

      {showForm && (
        <InvestForm
          propertyName={property.propertyName}
          size={property.size}
          onClose={() => setShowForm(false)}
        />
      )}
      <ToastContainer />
    </div>
  );
};

export default Property;
