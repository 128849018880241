import React, { useRef } from "react";
import Content1 from "../components/Content1";
import Content2 from "../components/Content2";
import Content3 from "../components/Content3";
import Content4 from "../components/Content4";
import Content5 from "../components/Content5";
import ContactForm from "../components/ContactForm";
import "./Homepage.css";
import Content6 from "../components/Content6";

const Homepage = () => {
  const contactFormRef = useRef(null);

  return (
    <div className="homepage-container">
      <Content1 contactFormRef={contactFormRef}/>
      <Content2 contactFormRef={contactFormRef} />
      <Content3 />
      <Content4 />
      <Content5 />
      <Content6/>
      <div ref={contactFormRef}>
        <ContactForm />
      </div>
    </div>
  );
};

export default Homepage;
