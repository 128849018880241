import axios from "axios";

const BACKEND_API = process.env.REACT_APP_BACKEND_URL;

const NEWS_API = {
  getNews: async (payload) => {
    return axios.request({
      url: `${BACKEND_API}news`,
      method: "get",
      data: payload,
    });
  },
};

export default NEWS_API;
