import React, { useEffect, useState } from "react";
import "./Content1.css";
import AOS from "aos";
import "aos/dist/aos.css";

const Content1 = ({ contactFormRef }) => {
  const [, setShowForm] = useState(false);

  const handleTicketClick = () => {
    setShowForm(true);
    setTimeout(() => {
      contactFormRef.current.scrollIntoView({ behavior: "smooth" });
    }, 0);
  };

  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);
  return (
    <div className="content1-container">
      <div className="container">
        <div className="row">
          <div className="col col-xl-auto col-lg-9 col-md-auto col-sm-auto col-xs-auto">
            <div className="hero-text">
              <p className="tagline">Create Your Safe</p>
              <h1 className="main-heading">Real Estate Portfolio</h1>
              <div className="sub-heading">
                <h2>Invest in Growing Physical Assets</h2>
                <p>
                  Invest in India's Growing Real Estate with Just ₹100,000!
                  <br/>Access pre-vetted, professionally managed properties from
                  anywhere in the world — all from the convenience of your
                  phone. Create your account today and start investing in
                  minutes.
                </p>
              </div>
            </div>
            <button
              className="call-to-action-button"
              onClick={handleTicketClick}
            >
              Contact Us
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Content1;
