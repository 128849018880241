import { DeleteObjectCommand, GetObjectCommand, PutObjectCommand, S3Client } from "@aws-sdk/client-s3";

const Bucket_name = 'investistt';

const s3Client = new S3Client({
    region: 'ap-south-1',
    credentials: {
        accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY,
        secretAccessKey: process.env.REACT_APP_S3_SECREAT_KEY
    }
});

export const uploadImage = async (data, key, type) => {
    const command = new PutObjectCommand({
        Bucket: Bucket_name,
        Key: key,
        Body: data,
        ContentType: type,
        ACL: 'public-read',
    });

    try {
        const response = await s3Client.send(command);
        if (response) return key;
    } catch (error) {
        console.log('upload error', error);
        return false;
    }
};

export const getImageUrl = async (key) => {
    const command = new GetObjectCommand({
        Bucket: Bucket_name,
        Key: key,
    });

    try {
        const response = await s3Client.send(command);
        const str = await response.Body.transformToString();
        return str;
    } catch (err) {
        console.error(err);
        return false;
    }
};

export const deleteImage = async (key) => {
    const command = new DeleteObjectCommand({
        Bucket: Bucket_name,
        Key: key,
    });

    try {
        const response = await s3Client.send(command);
        if (response) {
            return true;
        }
    } catch (err) {
        console.error(err);
        return false;
    }
};
