import React, { useState } from "react";
import "./FAQs.css";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import faqsData from "../FAQs.json";

const FAQs = () => {
  const [activeIndex, setActiveIndex] = useState({});

  const toggleFAQ = (sectionIndex, faqIndex) => {
    setActiveIndex((prevState) => ({
      ...prevState,
      [sectionIndex]: prevState[sectionIndex] === faqIndex ? null : faqIndex,
    }));
  };

  const renderFAQSection = (category, faqs, sectionIndex) => (
    <div className="faq-section" key={sectionIndex}>
      {/* <h2 className="section-title2">{category}</h2> */}
      <ul className="faqs-list">
        {faqs.map((faq, faqIndex) => (
          <li
            key={faqIndex}
            className={`faq-item ${
              activeIndex[sectionIndex] === faqIndex ? "active" : ""
            }`}
            onClick={() => toggleFAQ(sectionIndex, faqIndex)}
          >
            <div className="faq-question-container">
              <h3 className="faq-question">{faq.question}</h3>
              <span className="faq-icon">
                {activeIndex[sectionIndex] === faqIndex ? (
                  <FaChevronUp />
                ) : (
                  <FaChevronDown />
                )}
              </span>
            </div>
            <div
              className="faq-answer"
              style={{
                maxHeight:
                  activeIndex[sectionIndex] === faqIndex ? "200px" : "0px",
              }}
            >
              {activeIndex[sectionIndex] === faqIndex && <p>{faq.answer}</p>}
            </div>
          </li>
        ))}
      </ul>
    </div>
  );

  return (
    <div className="prop-bg">
      <h1 className="fw-bold text-center py-4" style={{ color: "#FFA500" }}>
        Frequently Asked Questions
      </h1>
      <div className="faqs-container">
        {Object.entries(groupByCategory(faqsData.faqs)).map(
          ([category, faqs], index) => renderFAQSection(category, faqs, index)
        )}
      </div>
    </div>
  );
};

// Helper function to group FAQs by category
const groupByCategory = (faqs) => {
  return faqs.reduce((acc, faq) => {
    if (!acc[faq.category]) {
      acc[faq.category] = [];
    }
    acc[faq.category].push(faq);
    return acc;
  }, {});
};

export default FAQs;
