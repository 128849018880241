import React, { useState } from "react";
import "./Content2.css";

const Content2 = ({ contactFormRef }) => {
  const [, setShowForm] = useState(false);

  const handleTicketClick = () => {
    setShowForm(true);
    setTimeout(() => {
      contactFormRef.current.scrollIntoView({ behavior: "smooth" });
    }, 0);
  };

  return (
    <div className="content2-container">
      <h2 className="section-title fw-semibold" style={{ color: "#0A3174" }}>
        Our Track Record
      </h2>
      <div className="container">
        <div className="row container2_row">
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-3 px-xl-5 px-lg-3 px-md-0 px-2">
            <div className="container">
              <div className="row">
                <div className="col d-flex flex-row justify-content-end">
                  <div className="stat">
                    <div className="number text-center">1000+</div>
                    <div className="label text-center">Trusted Users</div>
                  </div>
                </div>
                <div className="col d-flex flex-row justify-content-start">
                  <div className="stat">
                    <div className="number text-center">₹20+ Cr</div>
                    <div className="label text-center">AUM</div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col d-flex flex-row justify-content-end">
                  <div className="stat">
                    <div className="number text-center">2mn+</div>
                    <div className="label text-center">Sqft Managed</div>
                  </div>
                </div>
                <div className="col d-flex flex-row justify-content-start">
                  <div className="stat">
                    <div className="number text-center">₹1.3+ Cr</div>
                    <div className="label text-center">Profit Disbursed</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-3 col-xs-3">
            <div className="stat2">
              <div className="label-head text-lg-start text-md-start text-sm-start text-start">Build your safest real estate portfolio now</div>
              <div className="label-text text-lg-start text-md-start text-sm-start text-start">
                Be the part of India's fastest growing fractional and well managed real estate community and diverify your overall investment.
              </div>
              <div className="stat2-btn">
                <button className="stat-button" onClick={handleTicketClick}>Get Started</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="conatiner mx-lg-5 mx-md-3 mx-sm-1 my-3">
        <div className="row px-lg-5 px-md-3 px-sm-1">
          <div className="col second-head col-lg-6 col-md-6 col-sm-3 text-start py-md-2 py-sm-2">We Automate Slack Request</div>
          <div className="col second-text col-lg-6 col-md-6 col-sm-3 text-start py-lg-4 py-md-3 py-sm-1">
            Wrangle is the premief Slack management tool designed for Business
            Teams, There are a number of reasons why you may want to use Slack
            to manage your employees and Wrangle can make.
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default Content2;

