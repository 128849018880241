import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    token: null,
    userInfo: null,
}

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setToken: (state, action) => {
            state.token = action.payload
        },
        setUserInfo: (state, action) => {
            state.userInfo = action.payload;
        }
    },
})

// Action creators are generated for each case reducer function
export const { setToken, setUserInfo } = userSlice.actions

export default userSlice.reducer