import axios from "axios";

const BACKEND_API = process.env.REACT_APP_BACKEND_URL;

export const INVESTOR_API = {
    GetAllInvestors: () => {
        return axios.request({
            url: `${BACKEND_API}get-investors`,
            method: "get",
        });
    }
}