import React, { useState } from "react";
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { Link, useNavigate } from "react-router-dom";
import USER_API from "../services/UserService";
import { toast } from "react-toastify";

const Signup = () => {
  const [data, setData] = useState({
    name: "",
    email: "",
    contact_number: "",
    password: "",
    role: 1,
    login_type: 2,
  });
  const [confirmPasswd, setConfirmPasswd] = useState("");
  const [checkedTerm, setChekedTerm] = useState(false);
  const [errors, setErrors] = useState({});

  const provider = new GoogleAuthProvider();
  const auth = getAuth();
  const navigate = useNavigate();

  const validate = () => {
    const errors = {};

    if (!data.name.trim()) {
      errors.name = "Name is required";
    }
    if (!data.email.trim()) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(data.email)) {
      errors.email = "Email is invalid";
    }
    if (!data.contact_number.trim()) {
      errors.contact_number = "Contact Number is required";
    } else if (!/^\+?[1-9]\d{1,14}$/.test(data.contact_number)) {
      errors.contact_number = "Contact Number is invalid";
    }

    if (!data.password) {
      errors.password = "Password is required";
    } else if (
      !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(
        data.password
      )
    ) {
      errors.password =
        "Password must be at least 8 characters, include an uppercase letter, a lowercase letter, a number, and a special character";
    }
    if (!confirmPasswd) {
      errors.confirmPasswd = "Please confirm your password";
    } else if (confirmPasswd !== data.password) {
      errors.confirmPasswd = "Passwords do not match";
    }
    if (!checkedTerm) {
      errors.checkedTerm = "You must agree to the terms and conditions";
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleGoogleSignIn = async () => {
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      if (user) {
        const payload = {
          name: user.displayName,
          email: user.email,
          image: user.photoURL,
          login_type: 1,
          role: 1,
          token: user.accessToken,
        };
        const res = await USER_API.signUp(payload);
        if (res.status === 200) {
          navigate("/");
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleSignUp = async () => {
    if (!validate()) return;

    try {
      const payload = {
        ...data,
        email: data.email.toLowerCase(), // Convert email to lowercase
      };

      const resp = await USER_API.signUp(payload);
      if (resp.status === 200) {
        navigate("/login");
      } else {
        toast.error(resp.data.error);
      }
    } catch (error) {
      toast.error("Something went wrong");
    }
  };

  return (
    <div
      className=""
      style={{
        background:
          "linear-gradient(to bottom, rgba(10, 49, 116, 0.5), rgba(1, 9, 22, 0.2))",
        minWidthwidth: "100%",
      }}
    >
      <div className="container py-3">
        <div className="row justify-content-center align-items-center min-vh-100">
          <div className="col-md-6 col-lg-4 bg-light p-4 rounded shadow-sm">
            <h2 className="fs-3 text-dark mb-4 text-center">Signup</h2>
            <div className="text-center mb-4">
              <button className="btn btn-primary" onClick={handleGoogleSignIn}>
                Signup with Google
              </button>
            </div>
            <div className="text-center mb-3 text-dark"> OR </div>
            <div className="mb-3">
              <label htmlFor="firstName" className="form-label text-dark">
                Name
              </label>
              <input
                id="firstName"
                type="text"
                className="form-control"
                placeholder="First Name"
                onChange={(e) => {
                  setData((prev) => ({ ...prev, name: e.target.value }));
                }}
              />
              {errors.name && (
                <small className="text-danger">{errors.name}</small>
              )}
            </div>
            <div className="mb-3">
              <label htmlFor="contactNumber" className="form-label text-dark">
                Contact Number
              </label>
              <input
                id="contactNumber"
                type="text"
                className="form-control"
                placeholder="Contact Number"
                onChange={(e) => {
                  setData((prev) => ({
                    ...prev,
                    contact_number: e.target.value,
                  }));
                }}
              />
              {errors.contact_number && (
                <small className="text-danger">{errors.contact_number}</small>
              )}
            </div>
            <div className="mb-3">
              <label htmlFor="email" className="form-label text-dark">
                Email ID
              </label>
              <input
                id="email"
                type="email"
                className="form-control"
                placeholder="Email ID"
                onChange={(e) => {
                  setData((prev) => ({
                    ...prev,
                    email: e.target.value,
                  }));
                }}
              />
              {errors.email && (
                <small className="text-danger">{errors.email}</small>
              )}
            </div>
            <div className="mb-3">
              <label htmlFor="password" className="form-label text-dark">
                Password
              </label>
              <input
                id="password"
                type="password"
                className="form-control"
                placeholder="Password"
                onChange={(e) => {
                  setData((prev) => ({
                    ...prev,
                    password: e.target.value,
                  }));
                }}
              />
              {errors.password && (
                <small className="text-danger">{errors.password}</small>
              )}
            </div>
            <div className="mb-3">
              <label htmlFor="confirmPassword" className="form-label text-dark">
                Confirm Password
              </label>
              <input
                id="confirmPassword"
                type="password"
                className="form-control"
                placeholder="Confirm Password"
                onChange={(e) => {
                  setConfirmPasswd(e.target.value);
                }}
              />
              {errors.confirmPasswd && (
                <small className="text-danger">{errors.confirmPasswd}</small>
              )}
            </div>
            <div className="mb-3 form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id="agree"
                value={checkedTerm}
                onChange={() => setChekedTerm(!checkedTerm)}
              />
              <label className="form-check-label text-dark" htmlFor="agree">
                I agree to the terms and conditions
              </label>
              {errors.checkedTerm && (
                <small className="text-danger">{errors.checkedTerm}</small>
              )}
            </div>
            <div className="text-center mb-4">
              <button className="btn btn-primary w-100" onClick={handleSignUp}>
                Sign Up
              </button>
            </div>
            <div className="text-center">
              <p className="text-dark">
                Already have an account?
                <Link to="/login" className="text-primary">
                  Login
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signup;
