import React, { useState } from "react";
import { toast } from "react-toastify";
import USER_API from "../services/UserService";
import { useNavigate } from "react-router-dom";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errors, setErrors] = useState({});
  const [step, setStep] = useState("forgotPassword"); // "forgotPassword", "verifyOtp", "resetPassword"
  const navigate = useNavigate();

  const validateEmail = () => {
    const errors = {};
    if (!email.trim()) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      errors.email = "Email is invalid";
    }
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const validatePassword = () => {
    const errors = {};

    if (!password) {
      errors.password = "Password is required";
    } else if (
      !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(password)
    ) {
      errors.password =
        "Password must be at least 8 characters, include an uppercase letter, a lowercase letter, a number, and a special character";
    }

    if (!confirmPassword) {
      errors.confirmPassword = "Please confirm your password";
    } else if (confirmPassword !== password) {
      errors.confirmPassword = "Passwords do not match";
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleResetPassword = async () => {
    if (!validateEmail()) return;

    try {
      await USER_API.requestPasswordReset({ email: email.toLowerCase() });
      toast.success("OTP sent to your email. It will be valid for 10 min");
      setStep("verifyOtp");
    } catch (error) {
      console.error(error);
      toast.error("Failed to send OTP. Please try again.");
    }
  };

  const handleVerifyOtp = async () => {
    try {
      const response = await USER_API.verifyOtp({ email: email.toLowerCase(), otp });
      if (response.status === 200) {
        toast.success("OTP verified. You can now reset your password.");
        setStep("resetPassword");
      } else {
        toast.error("Invalid OTP. Please try again.");
      }
    } catch (error) {
      console.error(error);
      toast.error("Failed to verify OTP. Please try again.");
    }
  };

  const handlePasswordReset = async () => {
    if (!validatePassword()) return;

    try {
      await USER_API.resetPassword({ email: email.toLowerCase(), password });
      toast.success("Password has been reset successfully.");
      navigate("/login");
    } catch (error) {
      console.error(error);
      toast.error("Failed to reset password. Please try again.");
    }
  };

  return (
    <div
      className="d-flex align-items-center justify-content-center min-vh-100"
      style={{
        minWidth: "100vw",
        background: "linear-gradient(to bottom, rgba(10, 49, 116, 0.5), rgba(1, 9, 22, 0.2))",
      }}
    >
      <div className="bg-light p-4 rounded shadow-sm" style={{ maxWidth: "400px", width: "100%" }}>
        {step === "forgotPassword" && (
          <>
            <h2 className="fs-3 text-dark mb-4 text-center">Forgot Password</h2>
            <div className="mb-3">
              <label htmlFor="email" className="form-label text-dark">
                Email
              </label>
              <input
                id="email"
                type="email"
                className="form-control"
                placeholder="Enter your email"
                onChange={(e) => setEmail(e.target.value)}
              />
              {errors.email && <small className="text-danger">{errors.email}</small>}
            </div>
            <button className="btn btn-primary w-100 mb-4" onClick={handleResetPassword}>
              Send OTP
            </button>
          </>
        )}

        {step === "verifyOtp" && (
          <>
            <h2 className="fs-3 text-dark mb-4 text-center">Verify OTP</h2>
            <div className="mb-3">
              <label htmlFor="otp" className="form-label text-dark">
                OTP
              </label>
              <input
                id="otp"
                type="text"
                className="form-control"
                placeholder="Enter OTP"
                onChange={(e) => setOtp(e.target.value)}
              />
              {errors.otp && <small className="text-danger">{errors.otp}</small>}
            </div>
            <button className="btn btn-primary w-100 mb-4" onClick={handleVerifyOtp}>
              Verify OTP
            </button>
          </>
        )}

        {step === "resetPassword" && (
          <>
            <h2 className="fs-3 text-dark mb-4 text-center">Reset Password</h2>
            <div className="mb-3">
              <label htmlFor="password" className="form-label text-dark">
                New Password
              </label>
              <input
                id="password"
                type="password"
                className="form-control"
                placeholder="Enter your new password"
                onChange={(e) => setPassword(e.target.value)}
              />
              {errors.password && <small className="text-danger">{errors.password}</small>}
            </div>
            <div className="mb-3">
              <label htmlFor="confirmPassword" className="form-label text-dark">
                Confirm Password
              </label>
              <input
                id="confirmPassword"
                type="password"
                className="form-control"
                placeholder="Confirm your new password"
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
              {errors.confirmPassword && <small className="text-danger">{errors.confirmPassword}</small>}
            </div>
            <button className="btn btn-primary w-100 mb-4" onClick={handlePasswordReset}>
              Reset Password
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default ForgotPassword;
