import "./App.css";
import { Route, Routes, useLocation } from "react-router-dom";
import Navbar from "./components/Navbar";
import Homepage from "./pages/Homepage";
import About from "./pages/About";
import Properties from "./pages/Properties";
import Contact from "./pages/Contact";
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import { useEffect, useState } from "react";
import { initializeApp } from "firebase/app";
import { firebaseConfig } from "./helper/firebase_config";
import { AuthProvider } from "./helper/AuthenticationState";
import Error403 from "./errors/Error403";
import Error404 from "./errors/Error404";
import Footer from "./components/Footer";
import Property from "./components/Property";
import ProtectedRoute from "./helper/ProtectedRoute";
import News from "./pages/News";
import FAQs from "./components/FAQs";
import Privacy from "./components/Privacy";
import Risk from "./components/Risk";
import Terms from "./components/Terms";
import { ToastContainer } from "react-toastify";
import ForgotPassword from "./pages/ForgotPassword";
import MhowProperty from "./one_pagers/MhowProperty";
import KycForm from "./components/KycForm";





function App() {
  const [OnePager, setOnePager] = useState(false)
  const location = useLocation()



  useEffect(() => {
    initializeApp(firebaseConfig);
    window.scrollTo({ top: 0, behavior: 'smooth' })
    const disableHeaderAndFooter = ['/mhowProperty', '/kycForm']
    if (disableHeaderAndFooter.includes(location.pathname)) {
      setOnePager(true)
    } else {
      setOnePager(false)
    }

  }, [location]);



  return (
    <>
      <AuthProvider>
        {
          !OnePager && <Navbar />
        }

        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/about" element={<About />} />
          <Route path="/properties" element={<Properties />} />
          <Route path="/news" element={<News />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/faqs" element={<FAQs />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/risk" element={<Risk />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/mhowProperty" element={<MhowProperty />} />
          <Route path="/kycForm" element={<KycForm />} />
          <Route path="/login" element={
            <ProtectedRoute>
              <Login />
            </ProtectedRoute>
          } />
          <Route path="/signup" element={
            <ProtectedRoute>
              <Signup />
            </ProtectedRoute>
          } />
          <Route path="/403" element={<Error403 />} />
          <Route path="*" element={<Error404 />} />
          <Route path="/property" element={<Property />} />

        </Routes>
        {
          !OnePager && <Footer />
        }
        <ToastContainer />
      </AuthProvider>
    </>
  );
}

export default App;
