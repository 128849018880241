import React, { useState, useEffect } from "react";
import "./MohwProperty.css";
import { ToastContainer } from "react-toastify";
import { ImLocation } from "react-icons/im";
import PROPERTIES_API from "../services/PropertyService";
import FAQs2 from "../components/FAQs2";
import { IoRestaurantSharp } from "react-icons/io5";
import { MdTempleBuddhist } from "react-icons/md";
import { FaHotel } from "react-icons/fa6";
import { PiMountains } from "react-icons/pi";
import { MdOutlineRealEstateAgent } from "react-icons/md";
import { Link } from "react-router-dom";


const MhowProperty = () => {
  const [active, setActive] = useState(0);
  const [allProperty, setAllProperty] = useState([]);

  const getAllProperty = async () => {
    const res = await PROPERTIES_API.getProperty();
    if (res.status === 200) {
      const fetchedProperties = res.data;
      setAllProperty(fetchedProperties);
    }
  };

  useEffect(() => {
    getAllProperty();
  }, []);

  const renderPropertyDetails = () => {
    if (!allProperty) return null;

    return (
      <div className="property_box5">
        <div className="property_item5">
          <div className="property_label5">
            ₹{allProperty[7].minimumInvestment}
          </div>
          <div className="property_text5">Entry Price</div>
        </div>
        <div className="property_item5">
          <div className="property_label5">{allProperty[7].targetIRR}%</div>
          <div className="property_text5">Expected IRR</div>
        </div>
        <div className="property_item5">
          <div className="property_label5">₹{allProperty[7].price}</div>
          <div className="property_text5">Asset Value</div>
        </div>
        <div className="property_item5">
          <div className="property_label5">15 - 18%</div>
          <div className="property_text5">Entry Yield</div>
        </div>
        <div className="property_item5">
          <div className="property_label5">{allProperty[7].size} sqft</div>
          <div className="property_text5">Size</div>
        </div>
      </div>
    );
  };

  if (!allProperty[7]) {
    return <div>Loading...</div>; // Display loading state while data is being fetched
  }

  return (
    <div className="properties-container5">
      <div
        id="carouselExampleIndicators"
        className="carousel slide"
        data-bs-ride="carousel"
      >
        <div className="carousel-indicators">
          {allProperty[7].images?.map((ele, idx) => (
            <button
              key={idx}
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to={idx}
              className={idx === active ? "active" : ""}
              aria-current={idx === active ? "true" : undefined}
              aria-label={`Slide ${idx}`}
              onClick={() => setActive(idx)}
            ></button>
          ))}
        </div>
        <div className="carousel-inner">
          {allProperty[7].images?.map((ele, idx) => (
            <div
              key={idx}
              className={`carousel-item ${idx === active ? "active" : ""} p-0`}
            >
              <img
                src={`https://investistt.s3.ap-south-1.amazonaws.com/${ele}`}
                className="d-block crousal-image"
                alt="Property"
              />
            </div>
          ))}
        </div>
        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleIndicators"
          data-bs-slide="prev"
        >
          <span
            className="carousel-control-prev-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button
          className="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleIndicators"
          data-bs-slide="next"
        >
          <span
            className="carousel-control-next-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>

      <div className="property_details5">
        <div className="property-title5">{allProperty[7].propertyName}</div>
        <div className="property_location5">
          <ImLocation /> {allProperty[7].location}
        </div>

        {renderPropertyDetails()}

        <div className="rfi_section5">
          <h2>Reason for Investing</h2>
          <p>{allProperty[7].reasonForInvesting}</p>
        </div>

        <div className="atp_section5">
          <h2>About the Property</h2>
          <p>{allProperty[7].aboutTheProperty}</p>
        </div>
        <div className="location_details5">
          <h2>Location Proximity</h2>
          <div className="location_proximity container">
            <div className="row">
              <div className="col">
                <div className="d-flex">
                  <div className="icon5">
                    <IoRestaurantSharp />
                  </div>
                  <div>
                    <div className="label5">Maa Kastturi Shri</div>
                    <div className="text5">2.9 Km</div>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="icon5">
                    <MdTempleBuddhist />
                  </div>
                  <div>
                    <div className="label5">Shree Ahilyapur Balaji Mandir</div>
                    <div className="text5">0.9 Km</div>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="icon5">
                    <MdOutlineRealEstateAgent />
                  </div>
                  <div>
                    <div className="label5">AWC Bhicholi Project Mhow R-2</div>
                    <div className="text5">2.3 Km</div>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="icon5">
                    <FaHotel />
                  </div>
                  <div>
                    <div className="label5">Mount View Resort</div>
                    <div className="text5">5.4 Km</div>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="icon5">
                    <PiMountains />
                  </div>
                  <div>
                    <div className="label5">Janapava Hill</div>
                    <div className="text5">12 Km</div>
                  </div>
                </div>
              </div>
              <div className="col w-50 d-flex justify-content-center align-items-center">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3685.922218440759!2d75.6639848!3d22.507101299999995!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x396259b10d8d86fd%3A0x653f16bf098eef03!2sMount%20View!5e0!3m2!1sen!2sin!4v1726222256680!5m2!1sen!2sin"
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                  className="w-100 h-100"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="compleate_kyc my-5">
        <Link to="/kycForm" style={{ textDecoration: "none" }}>
          <button className="btn-kyc">Complete Your KYC</button>
        </Link>
      </div>

      <ToastContainer />
      <FAQs2 />
    </div>
  );
};

export default MhowProperty;
