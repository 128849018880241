import React from "react";
import "./ErrorPage.css";
import { Link } from "react-router-dom";
import { FaSkullCrossbones } from "react-icons/fa";

const Error403 = () => {
  return (
    <div className="error-page-container">
      <div className="error-content">
        <h1 className="error-title">
          <FaSkullCrossbones />
          403
        </h1>
        <h2 className="error-subtitle">Access Denied</h2>
        <p className="error-message">
          Sorry, you don't have permission to access this page.
        </p>
        <Link to="/" className="error-link">
          Go to Home
        </Link>
      </div>
    </div>
  );
};

export default Error403;
