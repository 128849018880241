import React, { useState } from 'react';
import './Terms.css';
import termsData from '../Terms.json';

const Terms = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleSection = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="prop-bg">
      <h1 className="fw-bold text-center py-4" style={{ color: "#FFA500" }}>
        Terms and Conditions
      </h1>
      <div className="terms-container">
        {termsData.sections.map((section, index) => (
          <div
            key={index}
            className={`terms-item ${activeIndex === index ? 'active' : ''}`}
            onClick={() => toggleSection(index)}
          >
            <div className="terms-title-container">
              <h3 className="terms-title">{section.heading}</h3>
            </div>
            {activeIndex === index && <p className="terms-content">{section.content}</p>}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Terms;
