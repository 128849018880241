// import React, { useState, useRef } from "react";
// import { useNavigate } from "react-router-dom";
// import "./Contact.css";
// import "bootstrap/dist/css/bootstrap.min.css";
// import {
//   FaEnvelope,
//   FaTicketAlt,
//   FaSignInAlt,
//   FaQuestionCircle,
//   FaInfoCircle,
// } from "react-icons/fa";
// import ContactForm from "../components/ContactForm";

// const Contact = () => {
//   const [showForm, setShowForm] = useState(false);
//   const contactFormRef = useRef(null);
//   const navigate = useNavigate(); // Initialize the useNavigate hook

//   const handleEmailClick = () => {
//     window.location.href = "mailto:tanisha@investist.in";
//   };

//   const handleTicketClick = () => {
//     setShowForm(true);
//     setTimeout(() => {
//       contactFormRef.current.scrollIntoView({ behavior: "smooth" });
//     }, 0);
//   };

//   const handleFAQsClick = () => {
//     navigate("/faqs"); // Navigate to the /faqs page
//   };

//   const handleAboutusClick = () => {
//     navigate("/about"); // Navigate to the /faqs page
//   };

//   const handleSignupClick = () => {
//     navigate("/signup"); // Navigate to the /faqs page
//   };

//   return (
//     <div className="contact-page">
//       <header className="contact-header text-center contact-page-head">
//         <div className="header-content">
//           <h1 className="header-title">Get In Touch</h1>
//           <p className="header-subtitle">
//             We're here to help you with any inquiries, feedback, or partnerships.
//           </p>
//           <div className="d-flex flex-column align-items-center mt-5">
//             <div className="row">
//               <div className="col-md-6 mb-3 mb-md-0">
//                 <div className="card card-hover h-100 text-center">
//                   <div className="card-body">
//                     <FaTicketAlt size={50} />
//                     <h5 className="card-title">Raise a Support Ticket</h5>
//                     <button className="btn btn-primary mt-3" onClick={handleTicketClick}>
//                       Submit Ticket
//                     </button>
//                   </div>
//                 </div>
//               </div>
//               <div className="col-md-6">
//                 <div className="card card-hover h-100 text-center">
//                   <div className="card-body">
//                     <FaEnvelope size={50} />
//                     <h5 className="card-title">Contact us via Email</h5>
//                     <button className="btn btn-primary mt-3" onClick={handleEmailClick}>
//                       Send Email
//                     </button>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </header>

//       <section className="container contact-section">
//         <h2 className="section-title">Looking for something else?</h2>
//         <p className="section-subtitle">Explore other ways to engage with us.</p>
//         <div className="row">
//           <div className="col-md-4">
//             <div className="card card-hover h-100 text-center">
//               <div className="card-body">
//                 <FaSignInAlt size={50} />
//                 <h5 className="card-title">Sign Up</h5>
//                 <p className="card-text text-start">Begin your investment journey by signing up.</p>
//                 <button className="btn btn-primary mt-3" onClick={handleSignupClick}>Sign Up</button>
//               </div>
//             </div>
//           </div>
//           <div className="col-md-4">
//             <div className="card card-hover h-100 text-center">
//               <div className="card-body">
//                 <FaQuestionCircle size={50} />
//                 <h5 className="card-title">FAQs</h5>
//                 <p className="card-text text-start">Learn more about how our services work.</p>
//                 <button className="btn btn-primary mt-3" onClick={handleFAQsClick}>FAQs</button>
//               </div>
//             </div>
//           </div>
//           <div className="col-md-4">
//             <div className="card card-hover h-100 text-center">
//               <div className="card-body">
//                 <FaInfoCircle size={50} />
//                 <h5 className="card-title">About Us</h5>
//                 <p className="card-text text-start">Discover more about our story and team.</p>
//                 <button className="btn btn-primary mt-3" onClick={handleAboutusClick}>About Us</button>
//               </div>
//             </div>
//           </div>
//         </div>
//       </section>

//       {showForm && (
//         <div ref={contactFormRef}>
//           <ContactForm />
//         </div>
//       )}
//     </div>
//   );
// };

// export default Contact;

// import React from "react";
// import "./Contact.css";

// const Contact = () => {
//   return (
//     <div>
//       <div>
//         <h1 className="contact-page-title">Contact Us</h1>
//         <p className="contact-page-text">
//           Investist is the most experienced and leading fractional ownership
//           platformin the country, working at the inersection of real estate and
//           technology.
//         </p>
//       </div>
//       <div className="blue-bg-with-icons">

//       </div>
//     </div>
//   );
// };

// export default Contact;

import React, { useState } from "react";
import "./Contact.css";
import FAQs2 from "../components/FAQs2";
import { IoIosPhonePortrait } from "react-icons/io";
import { MdEmail } from "react-icons/md";
import { ImLocation2 } from "react-icons/im";
import CONTACT_API from "../services/ContactService";

const Contact = () => {
  const [form, setForm] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    message: "",
  });

  const [responseMessage, setResponseMessage] = useState("");
  const [responseError, setResponseError] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setResponseMessage("");
    setResponseError("");

    // Combine firstName and lastName into a single name field
    const fullName = `${form.firstName} ${form.lastName}`;

    try {
      const response = await CONTACT_API.AddContact({
        name: fullName, // Send the combined name
        contact: form.phone,
        email: form.email,
        message: form.message,
      });

      if (response.status !== 200) {
        throw new Error("Network response was not ok");
      }

      setResponseMessage(
        "Form submitted successfully! We'll get back to you soon!"
      );
      setForm({
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        message: "",
      });
    } catch (error) {
      console.error("Error:", error);
      setResponseError("Something went wrong, please try again.");
    }
  };

  return (
    <div>
      <div className="contact-container">
        {/* Title and Description */}
        <div className="contact-header">
          <h1 className="contact-page-title">Contact Us</h1>
          <p className="contact-page-text">
            Investist is the most experienced and leading fractional ownership
            platform in the country, working at the intersection of real estate
            and technology.
          </p>
        </div>

        {/* Contact Information */}
        <div className="blue-bg-with-icons">
          <div className="contact-info">
            <div className="contact-item">
              <div className="contact-icon-container">
                <IoIosPhonePortrait className="contact-icon p-2" />
              </div>
              <p>(+91) 7024166889</p>
              <span>Monday - Friday 9am to 5pm</span>
            </div>
            <div className="contact-item">
              <div className="contact-icon-container">
                <MdEmail className="contact-icon p-2" />
              </div>
              <p>tanisha@investist.in</p>
              <span>We will reply as soon as possible</span>
            </div>
            <div className="contact-item">
              <div className="contact-icon-container">
                <ImLocation2 className="contact-icon p-2" />
              </div>
              <p>New Palasia, Indore</p>
              <span>G-2, 6/3, Princess Center (M.P.) 452001</span>
            </div>
          </div>
        </div>

        {/* Contact Form */}
        <div className="white-bg-container">
          <div className="contact-form-container3">
            <div className="contact_form_text">
              <h2>
                How Can We <span>Help You?</span>
              </h2>
              <p>
                Contact our office today for a <span>FREE consultation</span>.
                Fill out the form below to send us an e-mail, or give us a call
                at <span>(+91) 7024166889</span>
              </p>
            </div>
            <form className="contact-form3" onSubmit={handleSubmit}>
              <div className="form-row3">
                <div className="form-group3">
                  <label>
                    Your Name <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    placeholder=""
                    name="firstName"
                    value={form.firstName}
                    onChange={handleChange}
                    required
                  />
                  <p className="placeholder_text text-start">First Name</p>
                </div>
                <div className="form-group3">
                  <label>&nbsp;</label>
                  <input
                    type="text"
                    placeholder=""
                    name="lastName"
                    value={form.lastName}
                    onChange={handleChange}
                    required
                  />
                  <p className="placeholder_text text-start">Last Name</p>
                </div>
              </div>
              <div className="form-row3">
                <div className="form-group3">
                  <label>
                    Your Email <span className="text-danger">*</span>
                  </label>
                  <input
                    type="email"
                    placeholder=""
                    name="email"
                    value={form.email}
                    onChange={handleChange}
                    required
                  />
                  <p className="placeholder_text text-start">
                    example@example.com
                  </p>
                </div>
                <div className="form-group3">
                  <label>
                    Your Phone <span className="text-danger">*</span>
                  </label>
                  <input
                    type="number"
                    placeholder=""
                    name="phone"
                    value={form.phone}
                    onChange={handleChange}
                    required
                  />
                  <p className="placeholder_text text-start">
                    Please enter your valid phone number.
                  </p>
                </div>
              </div>
              <div className="form-group3">
                <label>
                  How we can help you? <span className="text-danger">*</span>
                </label>
                <textarea
                  placeholder="Type here..."
                  name="message"
                  value={form.message}
                  onChange={handleChange}
                  required
                />
              </div>
              <p className="privacy-text text-start">
                By clicking "Submit Message" you authorize Investist to send
                text messages and email messages with offers and other
                information. Consent is not a condition of purchase. Read
                complete privacy policy here
              </p>
              <div className="submit_button_div">
                <button type="submit" className="submit_button3">
                  Send Message
                </button>
              </div>
            </form>

            {responseMessage && (
              <div className="alert alert-success mt-3">{responseMessage}</div>
            )}
            {responseError && (
              <div className="alert alert-danger mt-3">{responseError}</div>
            )}
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-center">
        <FAQs2 />
      </div>
    </div>
  );
};

export default Contact;
