import React, { useState } from "react";
import "./FAQs2.css";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import faqsData from "../FAQs2.json";

const FAQs2 = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = (faqIndex) => {
    setActiveIndex(activeIndex === faqIndex ? null : faqIndex);
  };

  return (
    <div className="faqs-background3">
      <div className="faqs-container3">
        <h1 className="faqs-title3">FAQs</h1>
        <ul className="faqs-list3">
          {faqsData.map((faq, faqIndex) => (
            <li
              key={faqIndex}
              className={`faq-item3 ${activeIndex === faqIndex ? "active3" : ""}`}
              onClick={() => toggleFAQ(faqIndex)}
            >
              <div className="faq-question-container3">
                <h3 className="faq-question3">{faq.question}</h3>
                <span className="faq-icon3">
                  {activeIndex === faqIndex ? (
                    <FaChevronUp />
                  ) : (
                    <FaChevronDown />
                  )}
                </span>
              </div>
              <div className="faq-answer3">
                {activeIndex === faqIndex && <p>{faq.answer}</p>}
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default FAQs2;
