import React, { useContext, useState } from "react";
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { Link, useNavigate } from "react-router-dom";
import USER_API from "../services/UserService";
import { AuthContext } from "../helper/AuthenticationState";
import { toast } from "react-toastify";

const Login = () => {
  const [data, setData] = useState({
    email: "",
    password: "",
    login_type: 2,
    role: 1,
  });
  const [errors, setErrors] = useState({});
  const provider = new GoogleAuthProvider();
  const Gauth = getAuth();
  const navigate = useNavigate();
  const { LoginUser } = useContext(AuthContext);

  const validate = () => {
    const errors = {};

    if (!data.email.trim()) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(data.email)) {
      errors.email = "Email is invalid";
    }
    if (!data.password) {
      errors.password = "Password is required";
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleGoogleSignIn = async () => {
    try {
      const result = await signInWithPopup(Gauth, provider);
      const user = result.user;
      const payload = {
        email: user.email,
        login_type: 1,
        role: 1,
      };
      const res = await USER_API.login(payload);
      if (res.status === 200) {
        LoginUser(res.data);
        navigate("/");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleLoging = async () => {
    if (!validate()) return;

    try {
      const res = await USER_API.login(data);

      if (res.status === 200) {
        LoginUser(res.data);
        navigate("/");
      } else if (res.status === 400) {
        toast.error("Invalid email or password. Please try again.");
      } else if (res.status === 404) {
        toast.error("Account not found. Please sign up before logging in.");
      } else {
        toast.error("Something went wrong. Please try again later.");
      }
    } catch (error) {
      console.error(error);
      toast.error(
        "The email address or password you entered is incorrect. Please try again. If you do not have an account, kindly sign up first."
      );
    }
  };

  return (
    <div
      className="container d-flex align-items-center min-vh-100"
      style={{
        minWidth: "100vw",
        background:
          "linear-gradient(to bottom, rgba(10, 49, 116, 0.5), rgba(1, 9, 22, 0.2))",
      }}
    >
      <div
        className="row justify-content-center w-100"
        style={{ minWidth: "100vw" }}
      >
        <div className="col-md-6 col-lg-4 bg-light p-4 rounded shadow-sm">
          <h2 className="fs-3 text-dark mb-4 text-center">Login</h2>
          <button
            className="btn btn-primary w-100 mb-4"
            onClick={handleGoogleSignIn}
          >
            Sign in with Google
          </button>
          <div className="text-center text-dark mb-4">OR</div>
          <div className="mb-3">
            <label htmlFor="email" className="form-label text-dark">
              Email
            </label>
            <input
              id="email"
              type="email"
              className="form-control"
              placeholder="Email"
              onChange={(e) => {
                setData((prev) => ({
                  ...prev,
                  email: e.target.value.toLowerCase(),
                }));
              }}
            />
            {errors.email && (
              <small className="text-danger">{errors.email}</small>
            )}
          </div>
          <div className="mb-3">
            <label htmlFor="password" className="form-label text-dark">
              Password
            </label>
            <input
              id="password"
              type="password"
              className="form-control"
              placeholder="Password"
              onChange={(e) => {
                setData((prev) => ({ ...prev, password: e.target.value }));
              }}
            />
            {errors.password && (
              <small className="text-danger">{errors.password}</small>
            )}
          </div>
          <button className="btn btn-primary w-100 mb-4" onClick={handleLoging}>
            Login
          </button>
          <div className="text-center">
            <p className="text-dark">
              Don't have an account?{" "}
              <Link to="/signup" className="text-primary">
                Register
              </Link>
            </p>
            <p className="text-dark">
              <Link to="/forgot-password" className="text-primary">
                Forgot Password?
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
