import React, { useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { AuthContext } from "./AuthenticationState";

const ProtectedRoute = ({ redirectPath = "/", children }) => {
  const { currentUser } = useContext(AuthContext);

  if (currentUser) {
    return <Navigate to={redirectPath} />;
  }

  return children ? children : <Outlet />;
};

export default ProtectedRoute;
