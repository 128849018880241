import "./Footer.css";
// import { TiHeartFullOutline } from "react-icons/ti";
import { Link } from "react-router-dom";
import logo from "../assets/investistlogo.1 white.png";
import { FaLinkedin } from "react-icons/fa6";
import { RiTwitterXLine } from "react-icons/ri";
import { FaYoutube } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa";
import { MdFacebook } from "react-icons/md";

const Footer = () => {
  return (
    <footer className="footer text-white pt-5 pb-4">
      <div className="container">
        <div className="row">
          <div className="col-md-3 col-sm-6 mt-3">
            <img src={logo} alt="" className="logo-imag" />
            <div className="list-unstyled-icon">
              <Link to="https://www.linkedin.com/company/investist/">
                <FaLinkedin className="fs-3 fs-xl-3 fs-lg-3 fs-md-3 fs-sm-4 fs-xs-4" />
              </Link>
              <Link to="https://x.com/Investist44444?t=bzK_iWghKZS6JkDlUFFdTg&s=08">
                <RiTwitterXLine className="fs-3 fs-xl-3 fs-lg-3 fs-md-3 fs-sm-4 fs-xs-4" />
              </Link>
              <Link to="https://youtube.com/@investist-w4f?si=HLnts4HtzZT0B5xG">
                <FaYoutube className="fs-3 fs-xl-3 fs-lg-3 fs-md-3 fs-sm-4 fs-xs-4" />
              </Link>
              <Link to="https://www.instagram.com/investist.in?igsh=MTA1eXJibmR0ZHpwZg==">
                <FaInstagram className="fs-3 fs-xl-3 fs-lg-3 fs-md-3 fs-sm-4 fs-xs-4" />
              </Link>
              <Link to="https://www.facebook.com/profile.php?id=61560981067482&mibextid=ZbWKwL">
                <MdFacebook className="fs-3 fs-xl-3 fs-lg-3 fs-md-3 fs-sm-4 fs-xs-4" />
              </Link>
            </div>
          </div>

          {/* Company Section */}
          <div className="col-xl-2 col-lg-2 col-md-2 col-sm-6 mt-3">
            <h6 className="text-uppercase mb-4">Company</h6>
            <ul className="list-unstyled">
              <li>
                <Link to="/properties">Properties</Link>
              </li>
              <li>
                <Link to="/about">About Us</Link>
              </li>
              <li>
                <Link to="/contact">Contact Us</Link>
              </li>
              <li>
                <Link to="/">Investist</Link>
              </li>
            </ul>
          </div>

          {/* Resources Section */}
          <div className="col-xl-2 col-lg-2 col-md-2 col-sm-6 mt-3">
            <h6 className="text-uppercase mb-4">Resources</h6>
            <ul className="list-unstyled">
              <li>
                <Link to="/faqs">FAQs</Link>
              </li>
              {/* <li>
                <Link to="/properties">Investist Property</Link>
              </li> */}
              {/* <li>
                <Link to="/blog">Blog</Link>
              </li> */}
            </ul>
          </div>

          {/* Investment Options Section */}
          <div className="col-xl-3 col-lg-3 col-md-2 col-sm-6 mt-3">
            <h6 className="text-uppercase mb-4">
              Investment Options
            </h6>
            <ul className="list-unstyled">
              <li>
                <Link to="/properties">Investist Property</Link>
              </li>
            </ul>
          </div>

          {/* Legal Section */}
          <div className="col-xl-2 col-lg-2 col-md-3 col-sm-6 mt-3">
            <h6 className="text-uppercase mb-4">Legal</h6>
            <ul className="list-unstyled">
              <li className="text-start">
                <Link to="/terms" >Terms & Conditions</Link>
              </li>
              <li>
                <Link to="/privacy">Privacy Policy</Link>
              </li>
              <li>
                <Link to="/risk">Risk Disclosure</Link>
              </li>
            </ul>
          </div>
        </div>

        <hr className="my-4" />

        {/* Bottom row */}
        <div className="row align-items-center justify-content-center text-center">
          <div className="col-md-8">
            <p className="mb-0 text-center">
              &copy; 2024 Investist Proptech Private Limited.
              All Rights Reserved.
            </p>
            {/* <p className="text-center fs-5 pt-3 pb-0 mb-0">
              Made with{" "}
              <TiHeartFullOutline className="heart-icon text-danger fs-4" /> in
              India
            </p> */}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
