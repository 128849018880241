import React, { useEffect, useState } from "react";
import "./Content6.css";
import quote_img from "../assets/quote-img.webp";
import { INVESTOR_API } from "../services/InvestorService";

const Content6 = () => {
  const [investors, setInvestors] = useState([]);

  useEffect(() => {
    const getInvestors = async () => {
      try {
        const res = await INVESTOR_API.GetAllInvestors();
        if (res.status === 200) {
          setInvestors(res.data);
        }
      } catch (error) {
        console.log(error);
      }
    };

    getInvestors();
  }, []);
  return (
    <div>
      <div className="bg-full-white">
        <div className="video-box">
          <img className="double-quote-img" src={quote_img} alt="" />
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-12 col-sm-12 p-xl-4 p-lg-4">
                <h1 className="text-xl-start text-lg-start text-md-start text-sm-center text-center fw-bolder content6-header fw-semibold">
                  What our investors have to say
                </h1>
              </div>
              {investors.map((ele, index) => (
                <div className="col-lg-3 col-md-4 col-sm-12 d-flex justify-content-center">
                  <div className="video-card video16">
                    <iframe
                      className="video1"
                      width="100%"
                      height="auto"
                      src={ele.video_url}
                      title="YouTube video player"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowFullScreen
                    ></iframe>
                    <h4 className="video6-name">{ele.name}</h4>
                    <p className="video6-desig">{ele.designation}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Content6;
