import React, { useState } from 'react';
import './Risk.css';
import riskData from '../Risk.json';

const Risk = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleSection = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="prop-bg">
      <h1 className="fw-bold text-center py-4" style={{ color: "#FFA500" }}>
        Risk Disclosure
      </h1>
      <div className="risk-container">
        {riskData.sections.map((section, index) => (
          <div
            key={index}
            className={`risk-item ${activeIndex === index ? 'active' : ''}`}
            onClick={() => toggleSection(index)}
          >
            <div className="risk-title-container">
              <h3 className="risk-title">{section.heading}</h3>
            </div>
            {activeIndex === index && <p className="risk-content">{section.content}</p>}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Risk;
