import axios from "axios";

const BACKEND_API = process.env.REACT_APP_BACKEND_URL;

const KYC_API = {
  // Add KYC Details
  AddKycDetails: async (payload) => {
    return axios.request({
      url: `${BACKEND_API}add-kyc`,
      method: "post",
      data: payload,
      withCredentials: true,
    });
  },
};

export default KYC_API;
