import React from "react";
import "./Content5.css";
// import fractInvest from "../assets/fracInvest.webp";

const Content5 = () => {
  return (
    <div>
      <div className="bg-dark-blue">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-12 col-sm-12 invest_option_text">
              <h1 className="mt-4 fw-semibold">Investment Options</h1>
              <h6 className="mt-4 text-start">
                Investist provides multiple investment options catering to
                different risk profiles, preferences, and requirements.
              </h6>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-12 p-0 for-sm-content5">
              <div className="fractional5">
                <div className="child">
                  {/* <img src={fractInvest} alt="" className="w-100 h-100" /> */}
                  <div className="child_text">
                    <h3 className="d-flex text-start">
                      Purchase add Value & Sell
                    </h3>
                    <p className="text-start">
                      Buy, enhance property value, sell for higher returns.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-12 p-0 for-sm-content5">
              <div className="growth5 ">
                <h3 className="d-flex justify-content-start">
                  Purchase & Rent
                </h3>
                <p className="text-start">
                  Acquire property, generate steady income through rental
                  leases.
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-12 p-0 for-sm-content5">
              <div className="edge5">
                <h3 className="d-flex justify-content-start">Create Asset</h3>
                <p className="text-start">
                  Invest in property development to build high-value real
                  estate.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Content5;
